import React from "react"

import styled from "styled-components";

import Layout from "../components/layout"

const ContactContainer = styled.div`
  margin-bottom: 50%;
`;

const ContactPage = (props) => (
  <Layout pageTitle="Contact" buttonLink="/" buttonTitle="About">
    <h1>Kontakt</h1>
    <ContactContainer>
      <p>Melde dich bei mir über <a href="https://wa.me/41799111866">WhatsApp</a> oder <a href="mailto:sibylle@sibylleeckert.ch">E-Mail</a>.</p>
    </ContactContainer>
  </Layout>
)

export default ContactPage
